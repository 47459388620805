// import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import ShieldIcon from './shield-icon';
export default class CompanionBlocker extends Component {
    @service
    pixCompanion;
    constructor(...args1){
        super(...args1);
        this.pixCompanion.startCheckingExtensionIsEnabled();
    }
    willDestroy(...args1) {
        super.willDestroy(...args1);
        this.pixCompanion.stopCheckingExtensionIsEnabled();
    }
    get isBlocked() {
        return !this.pixCompanion.isExtensionEnabled;
    }
    static{
        template(`
    {{#if this.isBlocked}}
      <section class="companion-blocker">
        <ShieldIcon />
        <h1>
          {{t "common.companion.not-detected.title" htmlSafe=true}}
        </h1>
        <p>{{t "common.companion.not-detected.description"}}</p>
        {{!-- <PixButtonLink @href="https://pix.fr" target="_blank">{{t "common.companion.not-detected.link"}}</PixButtonLink> --}}
      </section>
    {{else}}
      {{yield}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
