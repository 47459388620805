import { template } from "@ember/template-compiler";
import CompanionBlocker from '../companion/blocker';
export default template(`
  {{#if @assessment.isCertification}}
    <CompanionBlocker>
      {{yield}}
    </CompanionBlocker>
  {{else}}
    {{yield}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
