import { template } from "@ember/template-compiler";
export default template(`
  <header class="authentication-layout-header" role="banner">
    <img class="authentication-layout-header__pix-logo" alt="" role="presentation" src="/images/pix-logo.svg" />
    {{yield}}
  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
